<template>
  <div>
    <TAdvancedSearch>
      <template v-slot:advanced-search>
        <Advanced
          :searchConfiguration.sync="searchConfiguration"
          :searchObj.sync="tableSubmit"
          :tableId.sync="table.tabId"
        ></Advanced>
      </template>
    </TAdvancedSearch>

    <KTPortlet title="Purchase Target">
      <template v-slot:title>
        <h3 class="kt-portlet__head-title">Purchase Target</h3>
      </template>
      <template v-slot:body>
        <b-overlay :show="loading" class="head-loading">
          <CustomTable
            :itemsFn="itemsFn"
            :tableData="table"
            :tableObj="tableSubmit"
          ></CustomTable>
        </b-overlay>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import apiBus from '@/common/apiBus/index';
import TAdvancedSearch from '@/components/TAdvancedSearch/Index';
import KTPortlet from '@/views/content/Portlet';
import Advanced from '@/components/Search/Ordinary';
import CustomTable from '@/components/Table/Index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
// import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
export default {
  name: 'PurchaseTarget',
  components: { TAdvancedSearch, KTPortlet, Advanced, CustomTable },
  data() {
    return {
      // 高级搜索配置
      searchConfiguration: {
        brand: {
          type: 'select',
          text: 'Brand',
          submitVal: null,
          multiple: true,
          selectable: 'disabled',
          placeholder: this.$t('page.loading'),
          options: []
        },
        category: {
          type: 'select',
          text: 'Category',
          submitVal: null,
          multiple: true,
          selectable: 'disabled',
          placeholder: this.$t('page.loading'),
          options: []
        },
        // brand_article_number: {
        //   type: 'input',
        //   text: 'Brand Article Number',
        //   input_type: 'number',
        //   // icon: 'bar-chart-fill',
        //   placeholder: this.$t('page.please_choose_input'),
        //   submitVal: null
        // },
        supplier_name: {
          type: 'input',
          text: 'Supplier Name',
          input_type: 'text',
          // icon: 'bar-chart-fill',
          placeholder: this.$t('page.please_choose_input'),
          submitVal: null
        }
      },
      table: {
        fields: [
          {
            key: 'supplier_id',
            label: 'SOA Company ID'
          },
          {
            key: 'supplier_name',
            label: 'Supplier Name'
          },
          {
            key: 'country',
            label: 'Country'
          },
          {
            key: 'main_brand',
            label: 'Main Brand'
          },
          {
            key: 'main_category',
            label: 'Main Category'
          },
          {
            key: 'purchase_limit',
            label: 'Purchase Limit'
          },
          {
            key: 'action',
            label: ''
          }
        ],
        tabId: 'supplier_management',
        rowClass: '',
        class: 'new_table',
        items: [],
        status: null,
        totalItems: 0,
        pageOptions: [10, 30, 50, 100, 200]
      },
      // 搜索提交数据
      tableSubmit: {
        status: [10, 20, 30],
        brand: [],
        category: [],
        supplier_name: null,
        limit: 100,
        page: 1
      },
      loading: true
    };
  },

  methods: {
    itemsFn() {
      console.log(this.currentUser);
      // this.CACHE.addCache(,,this.tableSubmit);
      this.loading = true;
      return apiBus.supplier
        .supplierManagement(this.tableSubmit)
        .then(data => {
          console.log(data);
          this.loading = false;
          this.table.items = data.data.data.list;
          this.table.totalItems = data.data.data.total;
          this.table.status = data.data.message;
          return this.table.items;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
          this.table.totalItems = 0;
          return [];
        });
    },
    searchTableData(data) {
      var tableData = [];
      data.forEach(key => {
        let {
          id,
          cps_order_number,
          order_date,
          sales_channel,
          brand,
          brand_article_number,
          product_name,
          qty,
          status,
          not_purchased_qty,
          purchasing_qty,
          purchased_qty,
          product_id
        } = key;

        tableData.push({
          id: id,
          cps_order_number: cps_order_number,
          order_date: order_date,
          sales_channel: sales_channel,
          brand: brand,
          brand_article_number: brand_article_number,
          product_name: product_name,
          qty: qty,
          status: status,
          progress: {
            not_purchased_qty: not_purchased_qty,
            purchasing_qty: purchasing_qty,
            purchased_qty: purchased_qty
          },
          product_id: product_id,
          action: 'PurchaseTarget'
        });
      });
      return tableData;
    },

    init() {
      // this.searchOptions();
    }
  },

  computed: {
    ...mapState({
      baseConfig: state => state.baseconfig.baseconfig
    }),
    ...mapGetters(['currentUser']) // 获取用户名
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped></style>
